<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
   <div id="page-content" v-if="!viLoader">
      <div class="dispflex"  >
        <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
         <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>View Class</h1>
              </div>
              <div style="margin-top:10px"> <input type="text" v-model="searchWords" placeholder="Search Class Name" /> </div>
              <div class="mt-1">
                
                <button class="btn gry_bg mpopup" v-b-modal.addclass>
                  <i class="fas fa-calendar-plus"></i>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Class Name</div>
                  <div class="cell">Last Modified By</div>
                   <div class="cell">Last Modified Date</div>
                  <div class="cell">Action</div>
                </div>

                <div
                  class="resrow"
                  v-for="(data, index) in classList"
                  :key="index"
                >
                 
                  <div class="cell" data-title="From Month">
                    {{ data.className }}
                  </div>
                 
                   <div class="cell" data-title="getUserName">
                    {{ data.updatedBy ? data.updatedBy.firstName + " " +  data.updatedBy.lastName : '' }}
                  </div>
                  <div  class="cell" data-title="Role">
                        {{ data.updatedDate | dateFormat }}
                  </div>
                  <div class="cell" data-title="Action">
                    <!-- <button
                      class="btn round orange tooltipt"
                      v-b-modal.addacademicyr
                      @click="showUpdateModal(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button> -->
                    <button
                      class="btn round red tooltipt"
                      @click="showDeleteModal(data._id)"
                    >
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            

            <div class="widjetfooter text-right dk_iconsml" >
             

              <div class="text-center mt-4" v-if="classList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
           </div>
          </div>
        </div>
      </div>
    </div>
   <div>
   <b-modal id="addclass" class="modal" no-close-on-backdrop no-close-on-esc>
      <div class="contact-form">
        <h2>Create Class</h2>
        <br />
        <div>
          <form
            data-vv-scope="classvalidate"
            id="stuprofile"
            accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="class Name"
                    v-model="classObj.className"
                    class="form-control form-input"
                    id="clsnameid"
                    placeholder="Class Name"
                  />
                  <label
                    v-if="errors.first('classvalidate.class Name')"
                    class="vi-error"
                    style="top: -1em"
                    >Class name is required</label
                  >

                  <label for="clsnameid" v-else
                    >Class Name <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon"></div>
              <div class="text-right dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createClass"
                >
                    <span>Save</span>
                </button>
                <button
                  @click.prevent="closeClassPopUp"
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
   </b-modal>
    <b-modal id="deleteClassPopup" no-close-on-backdrop no-close-on-esc>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button type="button" @click.prevent="closeClassDeletePopUp" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    </div>
 <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-fees-management",
  data() {
    return {
     
      classObj: {
        department: "",
        className: "",
      },
      classList: [],
      editId: null,
      deleteId: null,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 8, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      viLoader: false
    };
  },
  created() {
    this.getClassList(true);
  },
  computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                  this.getClassList(false);
              }
          }
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getClassList(false);
    },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getClassList(false);
    },
    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getClassList(false);
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
   
      closeClassPopUp() {
      
      this.classObj.className = ""
      this.classObj.section = ""
      this.$bvModal.hide("addclass");
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteClassPopup");
    },
    async createClass() {
      let isFormValid = false;

      await this.$validator.validateAll("classvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");
         
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.viLoader = true;

          const response = await ViService.viXPost("/class/createClass",this.classObj,userData.token);

          if (response.isSuccess) {
            this.closeClassPopUp();
            this.getClassList(false);
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
      closeClassDeletePopUp(){
     this.$bvModal.hide("deleteClassPopup");
     this.deleteId = null;
    },
     async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viDelete(
          `/class/deleteClass/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$bvModal.hide("deleteClassPopup");
          this.deleteId = null;
          this.getClassList(false);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getClassList(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        if(value){
          this.viLoader = true;
        }
        const response = await ViService.viXGet(`/class/getAllClassesListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);

        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);
           this.classList = resultData.classlist

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }
         
          
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    }
  },
  components: {
    ViSpinner
  }
};
</script>